import { debugAny } from '../utils/helpers';

export default class City {
   getCity() {
      const city = $.cookie('city') || 'Казань';
      const fullCity = {
         text: $(`.citylist > a[data-name="${city}"]`).eq(0).text(),
         idx: $(`.citylist > a[data-name="${city}"]`).eq(0).index(),
      };
      return { fullCity, city };
   }

   setCityText(text) {
      $('.selectphone > .city__mobile > span').text(text);

      return text;
   }

   setCityOnRdy() {
      const city = this.getCity().fullCity;
      this.setCityText(city.text);
      $('.selectphone > div:not(.city__mobile)')
         .eq(city.idx)
         .addClass('active');

      return $('.selectphone > div:not(.city__mobile)').eq(city.idx);
   }

   setCityHandler(selector = '.citylist') {
      const $this = this;
      let flag;
      $(selector)
         .find('a,button')
         .on('click', function (e) {
            const city = this.innerText;
            $this.setCityText(city);
            flag = city;
         });

      return flag;
   }
}
