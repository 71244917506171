import Hamburger from './hamburger';
import Catalog from './catalog';

export function craeteHamburger(selector) {
   return new Hamburger(selector);
}

export function createCatalog(selector) {
   return new Catalog(selector);
}
