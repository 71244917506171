import { isMobile } from './src/utils/helpers';
import City from './src/City';
import BackToTopToggle from './src/BackToTopToggle';
import BeforeFooter from './src/BeforeFooter';
import { craeteHamburger, createCatalog } from './src/Nav';
import { FixerOnPages } from './src/Pages';

import './media.less';

const flagMobile = isMobile();
const city = new City();
const beforeFooter = new BeforeFooter();
const hamburger = craeteHamburger();
const catalog = createCatalog();

city.setCityOnRdy();
FixerOnPages.fixShowMore();
if (flagMobile) {
   city.setCityHandler();

   beforeFooter.setFooterJobHTML();
   beforeFooter.setFooterVkWidget();

   hamburger.setNavMenu();
   hamburger.onClickToggle();

   FixerOnPages.wrapTabLabel();
   FixerOnPages.wrapTableResponsive();

   // catalog.setCatalogMenu();

   catalog.showBlockIsNotEmpty().then((flag) => {
      if (flag) {
         catalog.setValidClassCatalogMenu();
         catalog.onClickToggle();
      }
   });
}
