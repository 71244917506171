export default class Hamburger {
   constructor(selector) {
      if (selector) {
         this.$el = $(selector);
      } else {
         this.$el = $('.nav__mobile');
      }

      this.overlayClasses = ['aside-block-overflow', 'aside-overlay'];
   }

   getNavMenuDefaultHTML() {
      return $('nav .wrapper-menu').html();
   }

   setNavMenu() {
      const html = this.getNavMenuDefaultHTML();
      const aside = this.$el.find('#navAside');
      aside.html(html);
      aside.find('.topmenu').removeAttr('class').addClass('aside__menu');
   }

   onClickToggle() {
      const toggle = this.$el.find('#toggleAside');
      toggle.on('click', (e) => {
         if (this.$el.hasClass('opened')) {
            this.$el.removeClass('opened');
            this.$el.addClass('closed');

            this.overlayClasses.forEach((className) => {
               $('body').removeClass(className);
            });
         } else {
            this.$el.removeClass('closed');
            this.$el.addClass('opened');
            this.overlayClasses.forEach((className) => {
               $('body').addClass(className);
            });
         }
      });
   }
}
