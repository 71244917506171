export default class BackToTop {
   constructor(selector) {
      if (selector) {
         this.selector = selector;
      } else {
         this.selector = '.back_to_top';
      }
      this.footerPos = 0;
   }

   getFooterPosition() {
      return $('footer,.footer').eq(0).offset().top;
   }

   setFooterPosition() {
      this.footerPos = this.getFooterPosition();
   }

   setNewTogglePosition() {
      const pos = $(this.selector).offset().top;
      if ($(window).scrollTop() + 710 >= this.footerPos) {
         $(this.selector).css({
            position: 'absolute',
            top: `${this.footerPos - 75}px`,
         });
      } else {
         $(this.selector).attr('style', '');
      }

      return this.footerPos;
   }
}
