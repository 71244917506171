import { debugAny } from '../utils/helpers';

export const FixerOnPages = {
   fixShowMore() {
      const $desc = $('.innerdesc');
      const $toggle = $('.opendesc');
      const flag = $desc.text().trim().length;

      if (!flag) {
         $toggle.remove();
      }
   },
   wrapTabLabel() {
      const labels = $('.inneritem .tabs > label');
      labels.wrap('<div class="wrap__tab-item"></div>');
   },
   wrapTableResponsive() {
      const table = $('.content .text table');
      table.wrap('<div class="table-responsive"></div>');
   },
};
