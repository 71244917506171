export default class BeforeFooter {
   constructor() {
      this.selector = $('.before-footer');
      this.job = $('#jobFooter');
      this.vkwidget = $('#vkWidgetFooter');
   }

   setFooterJobHTML() {
      const html = $('.lcol__job').html();
      this.job.html(html);
   }

   setFooterVkWidget() {
      const html = $('#vk_groups').html();
      const styles = $('#vk_groups').attr('style');
      return Promise.resolve(
         VK.Widgets.Group(
            this.vkwidget.get('selector').slice(1),
            {
               redesign: 2,
               mode: 3,
               width: window.outerWidth - 30,
               height: '400',
               color1: 'FFFFFF',
               color2: '000000',
               color3: '5E81A8',
            },
            18401835
         )
      );
   }
}
