export default class Catalog {
   constructor(selector) {
      if (selector) {
         this.$el = $(selector);
      } else {
         this.$el = $('.cat__mobile');
      }

      this.$cat = this.$el.find('#catCategories');
      this.$toggle = this.$el.find('#toggleCat');
   }

   getCatMenuHTML() {
      return $('.cat > ul').get(0).outerHTML;
   }

   setCatalogMenu() {
      const html = this.getCatMenuHTML();
      this.$cat.html(html);
      this.$cat.find('ul').addClass('categories__wrapper');
   }

   setValidClassCatalogMenu() {
      this.$cat.find('ul').addClass('categories__wrapper');
   }

   showBlockIsNotEmpty() {
      return Promise.resolve(!!this.$cat.find(' > ul').length).then((flag) => {
         if (flag) {
            this.$el.addClass('showed');
         }

         return flag;
      });
   }

   onClickToggle() {
      const toggle = this.$toggle;
      const cat = this.$cat;
      toggle.on('click', (e) => {
         /* if (this.$el.hasClass('showed')) {
            this.$el.removeClass('showed');
         } */

         if (this.$el.hasClass('opened')) {
            cat.slideUp(300);
            this.$el.removeClass('opened');
            this.$el.addClass('closed');
         } else {
            cat.slideDown(300);
            this.$el.removeClass('closed');
            this.$el.addClass('opened');
         }
      });
   }
}
